// Functions

@function lighten($color, $percentage) {
  @return color-mix(in oklab, hsl(0, 0%, 100%) $percentage, $color);
}

@function lightenGrey($color, $percentage) {
  @return color-mix(in oklab, hsl(0, 0%, 90%) $percentage, $color);
}

@function darken($color, $percentage) {
  @return color-mix(in oklab, hsl(0, 0%, 5%) $percentage, $color);
}

@function lightenHSL($color, $percentage) {
  @return lighten(hsl(var($color)), $percentage);
}

@function darkenHSL($color, $percentage) {
  @return darken(hsl(var($color)), $percentage);
}

// Mixins

@mixin button-color($color, $base, $hover, $active) {
  @at-root button#{&}:hover:not(:disabled) {
    background-color: lighten($color, $hover);
  }

  @at-root button#{&}:focus:not(:disabled) {
    background-color: lighten($color, $hover);
  }

  @at-root button#{&}:active:not(:disabled) {
    background-color: lighten($color, $active);
  }

  @at-root button#{&}:disabled {
    background-color: lighten($color, calc($hover / 2));
  }
}

@mixin button-colorHSL($color, $base, $hover, $active) {
  @include button-color(hsl(var($color)), $base, $hover, $active);
}

@mixin base-color($background, $foreground) {
  background-color: $background;
  &,
  & * {
    color: $foreground;
  }
}

// Classes

.color- {
  &primary {
    @include base-color(hsl(var(--color-primary-hsl)), var(--color-white));
    @include button-colorHSL(--color-primary-hsl, 0%, 10%, 20%);
  }

  &primary-light {
    @include base-color(lightenHSL(--color-primary-hsl, 80%), darkenHSL(--color-primary-hsl, 50%));
    @include button-colorHSL(--color-primary-hsl, 1%, 10%, 20%);
  }

  &secondary,
  &grey {
    @include base-color(lightenHSL(--color-greyish-hsl, 80%), lightenHSL(--color-greyish-hsl, 0%));
    @include button-colorHSL(--color-greyish-hsl, 80%, 70%, 60%);
  }

  &grey-medium {
    @include base-color(lightenHSL(--color-greyish-hsl, 90%), lightenHSL(--color-greyish-hsl, 0%));
    @include button-colorHSL(--color-greyish-hsl, 90%, 80%, 70%);
  }

  &grey-light {
    @include base-color(lightenHSL(--color-greyish-hsl, 97%), lightenHSL(--color-greyish-hsl, 20%));
    @include button-colorHSL(--color-greyish-hsl, 97%, 90%, 80%);
  }

  &info {
    @include base-color(lighten(var(--color-info), 80%), darken(var(--color-info), 40%));
    @include button-color(var(--color-info), 0%, 10%, 20%);
  }

  &warn {
    @include base-color(lighten(var(--color-warn), 80%), darken(var(--color-warn), 40%));
    @include button-color(var(--color-warn), 0%, 10%, 20%);
  }

  @for $i from 1 through 9 {
    &primary-shift-#{$i} {
      --color-primary-shift: hsl(from hsl(var(--color-primary-hsl)) calc(#{$i} * 40 + h) s l);
    }
  }

  &chat-1 {
    --color-primary-shift: hsl(var(--color-primary-hsl));
  }
  @for $i from 1 through 9 {
    &chat-#{$i + 1} {
      @extend .color-primary-shift-#{$i};
    }
  }

  &chat {
    @include base-color(lightenGrey(var(--color-primary-shift), 90%), darken(var(--color-primary-shift), 50%));
  }
}
