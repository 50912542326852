/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@import '~@angular/cdk/overlay-prebuilt.css';

:root {
  --ion-safe-area-top: var(--safe-area-inset-top, env(safe-area-inset-top));
  --ion-safe-area-bottom: var(--safe-area-inset-bottom, env(safe-area-inset-bottom));
  --ion-safe-area-left: var(--safe-area-inset-left, env(safe-area-inset-left));
  --ion-safe-area-right: var(--safe-area-inset-right, env(safe-area-inset-right));
}

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import './variables.scss';

@import '~swiper/swiper-bundle.min.css';

/* Global Imports */
@import './font.scss';
@import './layout.scss';
@import './form.scss';
@import './dynamic-form.scss';
@import './calendar.scss';
@import './shapes.scss';
@import './color.scss';

:root,
:root [dir='ltr'] {
  --direction: 1;
}

:root [dir='rtl'] {
  --direction: -1;
}
/* Ionic Resets */

ion-card {
  contain: layout;
  margin-top: 0;

  ion-card-title {
    padding: 10px 10px 0 0;
  }

  &.card-transparent {
    margin-top: 1px;
    margin-bottom: 15px;
    padding: 0;
    border-radius: 0;
    --background: transparent;
    box-shadow: none !important;
    overflow: visible;

    ion-grid,
    ion-grid > ion-row {
      --ion-grid-padding: 0;
      --ion-grid-column-padding: 0;
    }

    ion-card-header {
      padding: 0;
    }
  }

  &.content-card {
    padding: 0 20px 10px;
  }
}

/* Global Custom CSS */

.tie-spinner {
  .loading-spinner {
    &::after {
      content: '';
      background-color: rgba(255, 0, 0, 0);
      width: 64px;
      height: 64px;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 20px;
      background-image: url('../../../../shared/src/lib/assets/images/loading-spinner.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    ion-spinner {
      opacity: 0;
    }
  }
  .loading-wrapper {
    border-radius: var(--radius-card);
    max-width: 600px;
    margin: 0px 20px;
  }
}

ion-buttons.toolbar-buttons {
  ion-button::part(native) {
    padding: 10px;
  }
}

ion-button {
  color: var(--color-white);
  border-radius: var(--radius-button);
  --border-radius: var(--radius-button);
  min-height: unset;

  &::part(native) {
    contain: layout;
  }

  &:not([size='small']) {
    height: auto;
    &::part(native) {
      padding: 10px 15px;
    }
  }

  &.icon-only {
    @include icon-only;
  }

  &[size='small'] {
    height: auto;
    &::part(native) {
      padding: 7px 13px;
    }
  }

  &[color='primary'] {
    box-shadow: var(--shadow-primary);
    overflow: hidden;
    --ion-color-primary: var(--color-primary-100);
    --ion-color-primary-tint: var(--color-primary-90);
    --ion-color-primary-shade: var(--color-primary-95);

    * {
      color: var(--color-white);
    }
  }

  &[color='secondary'] {
    --ion-color-secondary-contrast: var(--color-greyish-100);
    --ion-color-secondary: var(--color-white);
    --ion-color-secondary-tint: var(--color-primary-100);
    --ion-color-secondary-shade: var(--color-primary-100);
    box-shadow: var(--shadow-grey);
    overflow: hidden;

    * {
      color: var(--color-greyish-100);
    }

    &:hover,
    &:active,
    &.active {
      --ion-color-secondary-contrast: var(--color-white);
      --ion-color-base: var(--color-primary-100) !important;
      --ion-color-secondary-tint: var(--color-primary-100);
      --ion-color-secondary-shade: var(--color-primary-100);
      box-shadow: var(--shadow-primary);

      * {
        color: var(--color-white);
      }
    }
  }

  &[color='tertiary'] {
    --ion-color-tertiary-contrast: var(--color-greyish-95);
    --ion-color-tertiary: var(--color-greyish-20);
    --ion-color-tertiary-tint: var(--color-greyish-30);
    --ion-color-tertiary-shade: var(--color-greyish-40);
    overflow: hidden;

    * {
      color: var(--color-greyish-95);
    }
  }

  &[color='light'] {
    --ion-color-light-contrast: var(--color-greyish-95);
    --ion-color-light: var(--color-white);
    --ion-color-light-tint: var(--color-greyish-10);
    --ion-color-light-shade: var(--color-greyish-20);
    box-shadow: none;

    * {
      color: var(--color-greyish-95);
    }
  }

  &.button-thin {
    --padding: 5px;
    --padding-top: var(--padding);
    --padding-bottom: var(--padding);
    --padding-start: calc(2 * var(--padding));
    --padding-end: calc(2 * var(--padding));
    height: auto;
  }
}

ion-toggle {
  --track-background-checked: var(--color-primary-100);
}

fa-icon {
  &[slot='end'] {
    margin-left: 10px;
  }

  &[slot='start'] {
    margin-right: 10px;
  }
}

ion-back-button {
  &[color='primary'] {
    --ion-color-primary: var(--color-primary-100);
    --ion-color-primary-tint: var(--color-primary-90);
    --ion-color-primary-shade: var(--color-primary-95);
  }
  * {
    color: var(--color-white);
  }
}

ion-back-button,
.back-button,
.forward-button {
  height: auto;
  max-height: 32px;
}

.ion-button-group {
  display: flex;
  flex-direction: row;
  gap: 2px;

  ion-button {
    margin: 0;
  }
  ion-button:not(:first-child):not(:last-child),
  ion-button:not(:first-child):not(:last-child) button {
    border-radius: 0;
    --border-radius: 0;
  }

  ion-button:first-child,
  ion-button:first-child button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    --border-radius: 0;
  }

  ion-button:last-child,
  ion-button:last-child button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    --border-radius: 0;
  }
}

.video-call-form-wrapper form input[type='submit'] {
  border: 0;
  background: var(--color-primary-100);
  box-shadow: var(--shadow-primary);
  overflow: hidden;
  color: var(--color-white);
  border-radius: var(--radius-button) !important;
  height: auto;
  padding: 7px 10px;
  margin-top: 5px;
  font-family: var(--font-p3-mobile);
  font-size: var(--font-p3-mobile-font-size);
  line-height: var(--font-p-line-height, var(--line-height));
  text-align: var(--font-p3-mobile-text-align, center);
  font-weight: var(--font-p3-mobile-weight);
}

ion-toolbar {
  --background: white;
  ion-title {
    padding-left: var(--ion-padding, 16px);
  }
}

ion-card[color='light'] {
  border-radius: var(--radius-card);
  box-shadow: var(--shadow-grey);
  background: var(--color-white);

  ion-card-header {
    padding: 0 20px;
  }

  h2 {
    color: var(--color-greyish-100);
    margin: 5px 0;
  }
}

ion-card[color='primary'] {
  border-radius: var(--radius-card);
  --background: var(--color-primary-100);
  background-color: var(--color-primary-100);
  box-shadow: var(--shadow-primary);

  ion-card-header {
    padding: 0 30px;
  }

  h2 {
    color: var(--color-white);
    margin: 5px 0;
  }

  p {
    color: var(--color-white);
  }
}

ion-card-header ion-button {
  --border-radius: var(--radius-button);
}

.button-white-primary {
  --background: var(--color-white);
  --border-radius: var(--radius-card);
  --background-hover: var(--color-greyish-5);
  --background-activated: var(--color-greyish-5);
  p,
  fa-icon {
    color: var(--color-primary-100) !important;
  }
}

.button-white-secondary {
  --background: white;
  --color: var(--color-greyish-100);
  --border-radius: var(--radius-card);
  --padding-top: 1.5rem;
  --padding-bottom: 1.5rem;
  --box-shadow: none;
  --background-hover: var(--color-greyish-20);
  --background-activated: var(--color-greyish-20);
}

ion-segment {
  padding: 5px 10px 35px;
  margin-bottom: -20px;
  border-radius: calc(16 / 5 * var(--radius-small));
  box-shadow:
    inset 0 -5px 0 10px var(--color-white),
    inset 0 -25px 0 10px var(--color-white);
  mix-blend-mode: multiply;
  --background: var(--color-greyish-4);

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ion-segment-button {
    --border-radius: var(--radius-small);

    &:not(.segment-button-checked) {
      opacity: 0.8 !important;
      transition: opacity 100ms cubic-bezier(0.2, 1, 0, 1)-in-out;

      &:hover {
        opacity: 1 !important;
      }
    }

    &::part(indicator-background) {
      box-shadow: var(--shadow-primary-small);
    }

    &::part(native) {
      opacity: 1 !important;
    }
  }
}

.button-blue-glow {
  --box-shadow: var(--shadow-primary);
}

.segment-button-checked {
  --indicator-color: var(--color-primary-100);

  ion-label {
    color: var(--color-white);
  }
}

fa-icon {
  color: var(--color-greyish-100);

  &.primary {
    color: var(--color-primary-100);
  }

  &.secondary {
    color: var(--color-secondary-100);
  }

  &.greyish {
    color: var(--color-greyish-100);
  }

  &.light {
    color: var(--color-white);
  }

  &.outline {
    path {
      fill: none;
      stroke: currentColor;
      stroke-width: 37px;
    }

    &.pending {
      path {
        animation: glow;
        animation-duration: 1s;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        fill: currentColor;
      }
    }
  }

  @keyframes glow {
    from {
      fill-opacity: 0.1;
    }
    to {
      fill-opacity: 0.6;
    }
  }
}

ion-checkbox[color='primary'] {
  margin: auto;
  --size: 16px;
  --border-radius: 4px;
  --border-width: 1.2px;
  --border-color: var(--color-primary-100);
  --border-color-checked: var(--color-primary-100);
  --background-checked: var(--color-primary-100);
  --checkmark-color: var(--color-white);
  --checkmark-width: 2.5;

  &::part(mark) {
    filter: drop-shadow(0 1px 2px hsla(var(--color-greyish-hsl), 0.5));
    stroke-linejoin: round;
    stroke-linecap: round;
  }

  &.radio {
    --border-radius: 100%;
  }
}

ion-input,
ion-textarea {
  --background: var(--color-greyish-4) !important;
  &[color='light'] {
    --background: transparent !important;
    color: var(--color-greyish-100) !important;
  }
}

ion-input,
input:not(.file-upload),
ion-textarea,
ion-select,
textarea {
  border-radius: var(--radius-small);
  --border-radius: var(--radius-small);
}

ion-select {
  min-height: unset;
  display: flex;

  &::part(text),
  &::part(placeholder) {
    padding-right: 25px;
  }
  &::part(container) {
    flex-grow: 1;
  }
  &::part(icon) {
    color: var(--color-greyish-50);
  }
}

*[color='medium'] {
  --background: var(--color-greyish-3);
  --ion-color-medium: var(--color-greyish-100);
}

.profile-card {
  .profile-card-header {
    padding-top: 10px;
    display: inline-block;
    width: 100%;

    h2 {
      display: inline-block;
    }

    fa-icon {
      float: right;
      position: relative;
      top: 0.6rem;
      cursor: pointer;
      padding-right: 0;
    }
  }
}

ion-popover {
  --offset-y: 10px;

  &::part(content) {
    border-radius: var(--radius-card);
    box-shadow: var(--shadow-grey);
    width: fit-content;
  }

  &::part(arrow)::after {
    border: 1px solid var(--color-greyish-20);
  }
}

ion-list {
  --ion-item-background: transparent;

  ion-item {
    font-size: var(--font-p-font-size);
    --color: var(--color-greyish-100);
  }
}

ion-item {
  --background: transparent;
  --background-hover: var(--color-greyish-7);
  --background-hover-opacity: 1;
  --padding-start: 20px;
  --inner-padding-end: 20px;

  &.list-divider,
  &.card-header {
    --inner-padding-start: 10px;
    --padding-top: 5px;
    --padding-bottom: 5px;
    --background: var(--color-greyish-5);
  }

  &.layout {
    --min-height: unset;
    padding: 0;

    &.fit {
      width: fit-content;
    }

    &::part(native) {
      padding: 5px;
      border-radius: var(--radius-small);
      --inner-border-width: 0;
    }

    & > * {
      margin: 0;

      &:first-child {
        margin-left: 0;
        margin-right: 0.4em;
      }

      &:not(:nth-child(2)):last-child {
        margin-left: 0.4em;
        margin-right: 0;
      }
    }

    &.no-margin > * {
      margin: 0 !important;
    }
  }

  &[color='primary'] {
    --ion-color-primary: var(--color-primary-100);

    &:hover {
      --ion-color-primary: var(--color-primary-90);
    }

    &.ion-focused::part(native) {
      background-color: var(--color-primary-90);
    }

    &::part(native)::after {
      opacity: 0;
    }
  }

  &[color='light'] {
    --ion-color-light: var(--color-white);

    &:hover {
      --ion-color-light: var(--color-greyish-2);
    }

    &.ion-focused::part(native) {
      background-color: var(--color-greyish-10);
    }

    &::part(native)::after {
      opacity: 0;
    }
  }

  &[color='medium'] {
    --ion-color-medium: var(--color-greyish-3);
  }

  &[color='tertiary'] {
    --ion-color-tertiary: transparent;

    * {
      color: var(--color-white);
    }
  }
}

ion-item-divider {
  padding-left: 20px;

  &[color='primary'] {
    overflow: hidden;
    --ion-color-primary: var(--color-primary-100);
    --ion-color-primary-tint: var(--color-primary-90);
    --ion-color-primary-shade: var(--color-primary-95);

    * {
      color: var(--color-white);
    }
  }

  &[color='medium'] {
    --ion-color-medium: var(--color-greyish-10);
  }

  p {
    font-weight: normal;
    margin-bottom: 0;
  }
}

.icon-md {
  padding-right: 0.5em;
  --ionicon-stroke-width: 4rem;
  color: var(--color-greyish-80);
}

.icon-sm {
  padding-right: 0.5em;
  --ionicon-stroke-width: 3rem;
  color: var(--color-greyish-80);
  vertical-align: middle;
}

.background-svg {
  position: fixed;
  bottom: 2vh;
  right: 0;
  z-index: -1;
  transform: translateZ(-1px);
  background-color: #ffffff;

  img {
    max-height: 35vh;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-secondary-100);
    mix-blend-mode: lighten;
  }

  & ~ ion-content::part(background) {
    background-color: transparent;
  }

  ::selection,
  ::-moz-selection {
    background: transparent;
  }
}

form {
  padding: 0 5px 5px;
}

ion-datetime[color='medium'] {
  color: var(--color-greyish-100);
  --ion-text-color: var(--color-primary-100);
  --background: var(--color-white);
}

.input-notification-danger {
  color: var(--color-warn);
}

ion-alert {
  --backdrop-opacity: 0.2;

  .alert-wrapper {
    --min-width: 0px;
    padding: 2.5px;
    border-radius: var(--radius-card);
    background-color: var(--color-white);
    box-shadow: var(--shadow-grey);

    .alert-head {
      padding: 10px 20px;

      .alert-title {
        color: var(--color-greyish-100);
        font-size: var(--font-p-font-size);
        line-height: var(--font-p-font-line-height);
        font-family: var(--font-h3);
        text-transform: uppercase;
      }
    }

    .alert-message {
      color: var(--color-greyish-100);
      font-size: var(--font-p-font-size);
      line-height: var(--font-p-font-line-height);
      font-family: var(--font-p);
      padding: 10px 20px;
    }

    .alert-button-group {
      justify-content: center;
      padding: 20px 10px 10px;
      gap: 10px;

      .alert-button {
        border: none;
        font-size: var(--font-p-font-size);
        border-radius: var(--radius-button);

        &:hover {
          opacity: 0.8;
        }

        &:first-child {
          background: var(--color-primary-100);
          box-shadow: var(--shadow-primary);

          * {
            color: var(--color-white);
          }

          &.ion-activated {
            background: var(--color-primary-90);
          }
        }

        &:last-child {
          font-weight: inherit;
          color: var(--color-primary-100);
        }

        &.ion-activated {
          background: hsla(var(--color-greyish-hsl), 0.1);
        }
      }
    }
  }
}

.infinite-loading-text {
  font-size: 0.86rem;
  color: var(--color-greyish-67) !important;
}

.no-result {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-color: var(--color-white);
  padding: 100px 30px;

  .no-result-title {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--color-greyish-67);

    fa-icon {
      margin-bottom: 0.6rem;
      color: var(--color-greyish-67);
    }
  }

  .no-result-description {
    color: var(--color-greyish-67);
    text-align: center;
    margin-top: 2rem;
    line-height: 1.1rem;
  }
}

.page-title {
  margin-left: 15px;
}

.modal {
  &-appointment {
    --max-width: 450px;
  }
}

.trim-text-inline {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
  max-width: 100%;
}

ion-content::part(scroll) {
  padding-bottom: var(--ion-safe-area-bottom);
}

.ion-color-cancel-button {
  --color: var(--color-greyish-90) !important;
  --background: var(--color-greyish-10) !important;
  --ion-color-base: var(--color-greyish-10);
  --ion-color-tint: var(--color-greyish-7);
  mix-blend-mode: multiply;
}

pdf-viewer {
  .ng2-pdf-viewer-container {
    overflow-x: hidden !important;
  }

  .annotationLayer {
    pointer-events: none;

    .textWidgetAnnotation {
      input,
      textarea {
        border: none !important;
        font-size: 8px !important;
      }
    }
  }
}

.hide-accordion-toggle-icon {
  .ion-accordion-toggle-icon {
    display: none;
  }
}

.appointment-info .ion-accordion-toggle-icon {
  color: var(--color-greyish-100);
}

ion-spinner {
  --color: var(--color-greyish-90);
  width: 4rem;
  height: 4rem;
}

.pz-zoom-button {
  background-color: var(--color-primary-100) !important;
  opacity: 0.5 !important;
  border-radius: var(--radius-button) !important;
  height: 46px !important;
  width: 46px !important;
  background-size: 25px !important;
  margin-left: -23px !important;
  left: 50% !important;
  bottom: 20px !important;
  box-shadow: var(--shadow-primary);
}

.pz-zoom-button:hover {
  opacity: 1 !important;
}

.card-list {
  ion-item {
    --padding-vertical: 0px;
    --padding-top: var(--padding-vertical);
    --padding-bottom: var(--padding-vertical);
    --padding-start: 30px;
    --inner-padding-end: 30px;
    &.item-line {
      border-top: 1px solid var(--color-greyish-10);
    }

    [slot='end'] {
      max-width: 60%;
      text-align: right;
    }

    &.static {
      pointer-events: none;
      * {
        color: var(--color-greyish-50);
      }
    }
  }
}

.toolbar-buttons {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.modal-button-container {
  border-top: 1px solid var(--color-greyish-5);
}

ion-card.no-shadow,
.no-shadow * {
  box-shadow: none !important;
}

ion-item.grey-background,
.grey-background * {
  background: var(--color-greyish-10);
}

ion-item.white-background,
.white-background * {
  background: white;
}

.popover-item-list {
  ion-item {
    --min-height: 40px;
    --max-height: 40px;
    --border-radius: var(--radius-small);
    --transition: 0.3s cubic-bezier(0.2, 1, 0, 1);
    margin: 5px;
    &::part(native) {
      padding: 0;
    }

    &:hover {
      cursor: pointer;
      --background: var(--color-greyish-5);
    }

    &.information {
      &:hover {
        cursor: default;
      }
    }
  }

  .icon-container {
    aspect-ratio: 1 / 1;
    height: 100%;
    min-height: 40px;
    margin: 0;
    display: grid;
    place-items: center;
  }
}

cdk-virtual-scroll-viewport,
.table {
  .list-head {
    background-color: var(--color-white);
    position: sticky;
    top: 0;
    z-index: 2;
    border-bottom: 1px solid var(--color-greyish-7);
  }
}

.filters-popover {
  --offset-y: 10px;
  --offset-x: -10px;

  &::part(content) {
    border: none;
  }

  .filters-popover-content {
    border-radius: var(--radius-card);
    padding: 1rem;
  }
}

.filtering-and-sorting-popover {
  --min-width: 350px;
  --max-width: 350px;
  --height: 60%;
  --offset-y: 10px;
}

ion-modal.filtering-and-sorting-modal::part(handle) {
  position: absolute;
  top: 25px;
  background: var(--color-white);
  width: 70px;
}

ion-toast {
  --border-radius: 0;
  --background: transparent;

  &::part(container) {
    box-shadow: var(--shadow-primary);
    border-radius: var(--radius-card);
    background: var(--color-primary-100);
    color: var(--color-white);
  }
  &::part(button) {
    background: var(--color-white);
    margin: 5px;
    border-radius: calc(var(--radius-card) - 5px);
    color: var(--color-greyish-100);
    padding: 10px 20px;
    height: unset;
    font: inherit;
    display: flex;
    &:hover {
      opacity: 0.9;
    }
  }
  &::part(button cancel) {
    min-height: 0;
    height: 35px;
    color: var(--color-greyish-95);
    border-radius: 50%;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    margin: 9px;
    padding: 0 0 3px;
  }
}

ion-modal.second-layer {
  --backdrop-opacity: 0.32 !important;
}

.overflow-visible {
  overflow: visible;
}

ion-content {
  background: var(--color-white);
}

ion-content.no-safe-area {
  --ion-safe-area-top: 0px;
  --ion-safe-area-bottom: 0px;
  --ion-safe-area-left: 0px;
  --ion-safe-area-right: 0px;
}

.language-switch-popover {
  --offset-x: 0px;
  --ion-safe-area-left: calc(var(--ion-safe-area-left) + 20px);
}

ion-datetime {
  &::part(calendar-day disabled) {
    opacity: 0.16;
    text-decoration: line-through;
  }
  --background-rgb: unset;
}

ion-action-sheet.action-sheet {
  .action-sheet-wrapper {
    max-width: none;
  }

  .action-sheet-group {
    display: flex;
    flex-direction: column;

    &:first-of-type {
      .action-sheet-button:first-of-type {
        margin-top: 20px;
      }
    }

    &:last-of-type {
      .action-sheet-button:last-of-type {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }

  .action-sheet-button {
    border-radius: var(--radius-button);
    margin: 5px 0;
    max-width: 90%;
    min-height: 34px;
    height: 34px;
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: center;
    font-weight: bold;

    &[color='tertiary'] {
      background: var(--color-greyish-20);
      * {
        color: var(--color-greyish-100);
        & > * {
          color: var(--color-greyish-100);
        }
      }
      &:hover {
        background: var(--color-greyish-25);
      }
    }

    &[color='primary'] {
      background: var(--color-primary-100);
      * {
        color: var(--color-white);
        & > * {
          color: var(--color-white);
        }
      }
      &:hover {
        background: var(--color-primary-90);
      }
    }

    &[color='warn'] {
      background: color-mix(in srgb, var(--color-warn) 20%, white);
      span > * {
        color: color-mix(in srgb, var(--color-warn) 60%, black);
      }

      &:hover {
        background: color-mix(in srgb, var(--color-warn) 25%, white);
      }
    }

    span {
      display: flex;
      flex-direction: row-reverse;
      font-size: var(--font-p3-mobile-font-size);
      width: auto;
      ion-icon {
        font-size: var(--font-h5-font-size);
        margin-left: 10px;
        margin-right: 0;
      }
    }

    &.back-button > span {
      flex-direction: row;
      ion-icon {
        margin-left: 0;
        margin-right: 5px;
      }
    }
  }
}

.toolbar-item {
  &.card-layout {
    & > * {
      --border-radius: 0;
      border-radius: 0;
      & > * {
        border-radius: 0;
      }
      &:first-child,
      &:first-child > * {
        border-top-left-radius: var(--radius-card);
        border-bottom-left-radius: var(--radius-card);
      }
      &:last-child,
      &:last-child > * {
        border-top-right-radius: var(--radius-card);
        border-bottom-right-radius: var(--radius-card);
      }
    }

    button.tertiary {
      background: var(--color-greyish-10);
      &:hover {
        background-color: var(--color-greyish-15);
      }
      &:disabled {
        background-color: var(--color-greyish-10);
      }
    }
  }

  &:not(.card-layout) > * {
    --border-radius: 0;
    border-radius: 0;
    & > * {
      border-radius: 0;
    }
    &:first-child,
    &:first-child > * {
      border-top-left-radius: var(--radius-small);
      border-bottom-left-radius: var(--radius-small);
    }

    &:last-child,
    &:last-child > * {
      border-top-right-radius: var(--radius-small);
      border-bottom-right-radius: var(--radius-small);
    }

    button.tertiary {
      background: var(--color-greyish-20);
      &:hover {
        background-color: var(--color-greyish-25);
      }
      &[disabled] {
        background-color: var(--color-greyish-20);
      }
    }
  }

  button {
    &.primary {
      font-weight: bold;
      background: var(--color-primary-100);
      * {
        color: var(--color-white);
      }
      &:hover {
        background-color: var(--color-primary-90);
      }
    }

    &.tertiary {
      * {
        color: var(--color-greyish-100);
      }
    }

    &:disabled {
      > * {
        opacity: 0.5;
      }
    }

    fa-icon > svg {
      height: 1em;
    }
  }
}

.hidden-label-iframe {
  border: none;
  visibility: hidden;
  height: 0;
  width: 0;
  position: absolute;
}

.widget {
  &-card {
    border-radius: var(--radius-card);
    box-shadow: var(--shadow-grey);
    height: 100%;
    overflow: hidden;

    .widget-header {
      h2 {
        margin: 0!important;
      }
    }

    .no-results {
      border-top: 1px solid var(--color-greyish-7);
    }
  }

  &-content {
    background-color: var(--color-greyish-5);
  }
}

/* Fix for landscape mode in Safari Mobile for Ionic 7.7.2 */
@media (orientation: landscape) {
  ion-menu-toggle,
  lib-context-switch {
    --ion-safe-area-left: 0px !important;
  }

  ion-menu-toggle {
    ion-item {
      fa-icon {
        margin-inline: 0 !important;
        margin-left: 0 !important;
      }
    }
  }

  lib-context-switch {
    ion-avatar {
      margin-left: 0px !important;
      margin-inline: 0px !important;
    }
  }
}

pdf-viewer {
  .page {
    max-width: 100%;
    height: auto !important;
    .canvasWrapper {
      canvas {
        max-width: 100%;
        height: auto !important;
      }
    }
  }
}

.copyright-additional-disclaimer-link {
  font-size: inherit !important;
}

.no-hover {
  pointer-events: none;
}

/** @deprecated try to remove after some ionic updates */
ion-split-pane.themer-temporary-fix {
  lib-themer {
    max-width: 350px;
    overflow-y: auto;
    @media (max-width: 768px) {
      display: none;
    }
  }
}

.element-loading-overlay {
  position: relative;
  overflow: hidden;
  pointer-events: none;

  &::before {
    border-radius: var(--radius-card);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    z-index: 1;
  }
  &::after {
    border-radius: var(--radius-card);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 70%,
      rgba(255, 255, 255, 0) 100%
    );
    background-size: 200% 100%;
    animation: shimmer 3s infinite;
    z-index: 2;
    animation-timing-function: linear;
    animation-direction: reverse;
  }
}

@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
