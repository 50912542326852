// // Ionic Variables and Theming. For more info, please see:
// // http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  // Logo
  --logo-padding: 0px;
  --logo-padding-mobile: 0px;

  // Fonts

  --font-h1: 'TF H1', 'TF H', 'TF P', 'SF BOLD', sans-serif;
  --font-h1-font-size: 32px;

  --font-h2: 'TF H2', 'TF H', 'TF P', 'SF BOLD', sans-serif;
  --font-h2-font-size: 19px;

  --font-h3: 'TF H3', 'TF H', 'TF P', 'SF BOLD', sans-serif;
  --font-h3-font-size: 16px;

  --font-h4: 'TF H4', 'TF H', 'TF P', 'SF BOLD', sans-serif;
  --font-h4-font-size: 60px;

  --font-h5: 'TF H5', 'TF H', 'TF P', 'SF BOLD', sans-serif;
  --font-h5-font-size: 18px;

  --font-p: 'TF P', 'SF REGULAR', sans-serif;
  --font-p-font-size: 16px;

  --font-p1: 'TF P1', 'TF P', 'SF REGULAR', sans-serif;
  --font-p1-font-size: 16px;

  --font-p2: 'TF P2', 'TF P', 'SF REGULAR', sans-serif;
  --font-p2-font-size: 16px;

  --font-p3: 'TF P3', 'TF P', 'SF SEMIBOLD', sans-serif;
  --font-p3-font-size: 15px;

  --font-p4: 'TF P4', 'TF P', 'SF SEMIBOLD', sans-serif;
  --font-p4-font-size: 15px;

  --font-a: 'TF A', 'TF P', 'SF REGULAR', sans-serif;
  --font-a-font-size: 13px;

  --font-label-input: 'TF LABEL', 'TF P', 'SF MEDIUM', sans-serif;
  --font-label-input-font-size: 14px;

  --font-h1-mobile: 'TF H1M', 'TF H1', 'TF H', 'TF P', 'SF BOLD', sans-serif;
  --font-h1-mobile-font-size: 26px;

  --font-h2-mobile: 'TF H2M', 'TF H2', 'TF H', 'TF P', 'SF BOLD', sans-serif;
  --font-h2-mobile-font-size: 19px;

  --font-h3-mobile: 'TF H3M', 'TF H3', 'TF H', 'TF P', 'SF SEMIBOLD', sans-serif;
  --font-h3-mobile-font-size: 16px;

  --font-h4-mobile: 'TF H4M', 'TF H4', 'TF H', 'TF P', 'SF BOLD', sans-serif;
  --font-h4-mobile-font-size: 35px;

  --font-h5-mobile: 'TF H5M', 'TF H5', 'TF H', 'TF P', 'SF BOLD', sans-serif;
  --font-h5-mobile-font-size: 18px;

  --font-p-mobile: 'TF PM', 'TF P', 'SF REGULAR', sans-serif;
  --font-p-mobile-font-size: 14px;

  --font-p2-mobile: 'TF P2M', 'TF P', 'SF REGULAR', sans-serif;
  --font-p2-mobile-font-size: 12px;

  --font-p3-mobile: 'TF P3M', 'TF P', 'SF SEMIBOLD', sans-serif;
  --font-p3-mobile-font-size: 14px;

  --font-p4-mobile: 'TF P4M', 'TF P', 'SF SEMIBOLD', sans-serif;
  --font-p4-mobile-font-size: 15px;

  // ion fallback
  --ion-font-family: var(--font-p, sans-serif);

  // Color Fallback – don't edit these values

  --color-primary-hsl: 210, 100%, 50%;
  --color-primary-100: hsl(210, 100%, 50%);
  --color-primary-99: hsl(210, 100%, 51%);
  --color-primary-98: hsl(210, 100%, 51%);
  --color-primary-97: hsl(210, 100%, 52%);
  --color-primary-96: hsl(210, 100%, 52%);
  --color-primary-95: hsl(210, 100%, 53%);
  --color-primary-94: hsl(210, 100%, 53%);
  --color-primary-93: hsl(210, 100%, 54%);
  --color-primary-92: hsl(210, 100%, 54%);
  --color-primary-91: hsl(210, 100%, 55%);
  --color-primary-90: hsl(210, 100%, 55%);
  --color-primary-89: hsl(210, 100%, 56%);
  --color-primary-88: hsl(210, 100%, 56%);
  --color-primary-87: hsl(210, 100%, 57%);
  --color-primary-86: hsl(210, 100%, 57%);
  --color-primary-85: hsl(210, 100%, 58%);
  --color-primary-84: hsl(210, 100%, 58%);
  --color-primary-83: hsl(210, 100%, 59%);
  --color-primary-82: hsl(210, 100%, 59%);
  --color-primary-81: hsl(210, 100%, 60%);
  --color-primary-80: hsl(210, 100%, 60%);
  --color-primary-79: hsl(210, 100%, 61%);
  --color-primary-78: hsl(210, 100%, 61%);
  --color-primary-77: hsl(210, 100%, 62%);
  --color-primary-76: hsl(210, 100%, 62%);
  --color-primary-75: hsl(210, 100%, 63%);
  --color-primary-74: hsl(210, 100%, 63%);
  --color-primary-73: hsl(210, 100%, 64%);
  --color-primary-72: hsl(210, 100%, 64%);
  --color-primary-71: hsl(210, 100%, 65%);
  --color-primary-70: hsl(210, 100%, 65%);
  --color-primary-69: hsl(210, 100%, 66%);
  --color-primary-68: hsl(210, 100%, 66%);
  --color-primary-67: hsl(210, 100%, 67%);
  --color-primary-66: hsl(210, 100%, 67%);
  --color-primary-65: hsl(210, 100%, 68%);
  --color-primary-64: hsl(210, 100%, 68%);
  --color-primary-63: hsl(210, 100%, 69%);
  --color-primary-62: hsl(210, 100%, 69%);
  --color-primary-61: hsl(210, 100%, 70%);
  --color-primary-60: hsl(210, 100%, 70%);
  --color-primary-59: hsl(210, 100%, 71%);
  --color-primary-58: hsl(210, 100%, 71%);
  --color-primary-57: hsl(210, 100%, 72%);
  --color-primary-56: hsl(210, 100%, 72%);
  --color-primary-55: hsl(210, 100%, 73%);
  --color-primary-54: hsl(210, 100%, 73%);
  --color-primary-53: hsl(210, 100%, 74%);
  --color-primary-52: hsl(210, 100%, 74%);
  --color-primary-51: hsl(210, 100%, 75%);
  --color-primary-50: hsl(210, 100%, 75%);
  --color-primary-49: hsl(210, 100%, 76%);
  --color-primary-48: hsl(210, 100%, 76%);
  --color-primary-47: hsl(210, 100%, 77%);
  --color-primary-46: hsl(210, 100%, 77%);
  --color-primary-45: hsl(210, 100%, 78%);
  --color-primary-44: hsl(210, 100%, 78%);
  --color-primary-43: hsl(210, 100%, 79%);
  --color-primary-42: hsl(210, 100%, 79%);
  --color-primary-41: hsl(210, 100%, 80%);
  --color-primary-40: hsl(210, 100%, 80%);
  --color-primary-39: hsl(210, 100%, 81%);
  --color-primary-38: hsl(210, 100%, 81%);
  --color-primary-37: hsl(210, 100%, 82%);
  --color-primary-36: hsl(210, 100%, 82%);
  --color-primary-35: hsl(210, 100%, 83%);
  --color-primary-34: hsl(210, 100%, 83%);
  --color-primary-33: hsl(210, 100%, 84%);
  --color-primary-32: hsl(210, 100%, 84%);
  --color-primary-31: hsl(210, 100%, 85%);
  --color-primary-30: hsl(210, 100%, 85%);
  --color-primary-29: hsl(210, 100%, 86%);
  --color-primary-28: hsl(210, 100%, 86%);
  --color-primary-27: hsl(210, 100%, 87%);
  --color-primary-26: hsl(210, 100%, 87%);
  --color-primary-25: hsl(210, 100%, 88%);
  --color-primary-24: hsl(210, 100%, 88%);
  --color-primary-23: hsl(210, 100%, 89%);
  --color-primary-22: hsl(210, 100%, 89%);
  --color-primary-21: hsl(210, 100%, 90%);
  --color-primary-20: hsl(210, 100%, 90%);
  --color-primary-19: hsl(210, 100%, 91%);
  --color-primary-18: hsl(210, 100%, 91%);
  --color-primary-17: hsl(210, 100%, 92%);
  --color-primary-16: hsl(210, 100%, 92%);
  --color-primary-15: hsl(210, 100%, 93%);
  --color-primary-14: hsl(210, 100%, 93%);
  --color-primary-13: hsl(210, 100%, 94%);
  --color-primary-12: hsl(210, 100%, 94%);
  --color-primary-11: hsl(210, 100%, 95%);
  --color-primary-10: hsl(210, 100%, 95%);
  --color-primary-9: hsl(210, 100%, 96%);
  --color-primary-8: hsl(210, 100%, 96%);
  --color-primary-7: hsl(210, 100%, 97%);
  --color-primary-6: hsl(210, 100%, 97%);
  --color-primary-5: hsl(210, 100%, 98%);
  --color-primary-4: hsl(210, 100%, 98%);
  --color-primary-3: hsl(210, 100%, 99%);
  --color-primary-2: hsl(210, 100%, 99%);
  --color-primary-1: hsl(210, 100%, 100%);

  --color-secondary-hsl: 210, 100%, 93%;
  --color-secondary-100: hsl(210, 100%, 93%);
  --color-secondary-99: hsl(210, 100%, 93%);
  --color-secondary-98: hsl(210, 100%, 94%);
  --color-secondary-97: hsl(210, 100%, 94%);
  --color-secondary-96: hsl(210, 100%, 95%);
  --color-secondary-95: hsl(210, 100%, 95%);
  --color-secondary-94: hsl(210, 100%, 96%);
  --color-secondary-93: hsl(210, 100%, 96%);
  --color-secondary-92: hsl(210, 100%, 97%);
  --color-secondary-91: hsl(210, 100%, 97%);
  --color-secondary-90: hsl(210, 100%, 98%);
  --color-secondary-89: hsl(210, 100%, 98%);
  --color-secondary-88: hsl(210, 100%, 99%);
  --color-secondary-87: hsl(210, 100%, 99%);
  --color-secondary-86: hsl(210, 100%, 100%);
  --color-secondary-85: hsl(0, 0%, 100%);
  --color-secondary-84: hsl(0, 0%, 100%);
  --color-secondary-83: hsl(0, 0%, 100%);
  --color-secondary-82: hsl(0, 0%, 100%);
  --color-secondary-81: hsl(0, 0%, 100%);
  --color-secondary-80: hsl(0, 0%, 100%);
  --color-secondary-79: hsl(0, 0%, 100%);
  --color-secondary-78: hsl(0, 0%, 100%);
  --color-secondary-77: hsl(0, 0%, 100%);
  --color-secondary-76: hsl(0, 0%, 100%);
  --color-secondary-75: hsl(0, 0%, 100%);
  --color-secondary-74: hsl(0, 0%, 100%);
  --color-secondary-73: hsl(0, 0%, 100%);
  --color-secondary-72: hsl(0, 0%, 100%);
  --color-secondary-71: hsl(0, 0%, 100%);
  --color-secondary-70: hsl(0, 0%, 100%);
  --color-secondary-69: hsl(0, 0%, 100%);
  --color-secondary-68: hsl(0, 0%, 100%);
  --color-secondary-67: hsl(0, 0%, 100%);
  --color-secondary-66: hsl(0, 0%, 100%);
  --color-secondary-65: hsl(0, 0%, 100%);
  --color-secondary-64: hsl(0, 0%, 100%);
  --color-secondary-63: hsl(0, 0%, 100%);
  --color-secondary-62: hsl(0, 0%, 100%);
  --color-secondary-61: hsl(0, 0%, 100%);
  --color-secondary-60: hsl(0, 0%, 100%);
  --color-secondary-59: hsl(0, 0%, 100%);
  --color-secondary-58: hsl(0, 0%, 100%);
  --color-secondary-57: hsl(0, 0%, 100%);
  --color-secondary-56: hsl(0, 0%, 100%);
  --color-secondary-55: hsl(0, 0%, 100%);
  --color-secondary-54: hsl(0, 0%, 100%);
  --color-secondary-53: hsl(0, 0%, 100%);
  --color-secondary-52: hsl(0, 0%, 100%);
  --color-secondary-51: hsl(0, 0%, 100%);
  --color-secondary-50: hsl(0, 0%, 100%);
  --color-secondary-49: hsl(0, 0%, 100%);
  --color-secondary-48: hsl(0, 0%, 100%);
  --color-secondary-47: hsl(0, 0%, 100%);
  --color-secondary-46: hsl(0, 0%, 100%);
  --color-secondary-45: hsl(0, 0%, 100%);
  --color-secondary-44: hsl(0, 0%, 100%);
  --color-secondary-43: hsl(0, 0%, 100%);
  --color-secondary-42: hsl(0, 0%, 100%);
  --color-secondary-41: hsl(0, 0%, 100%);
  --color-secondary-40: hsl(0, 0%, 100%);
  --color-secondary-39: hsl(0, 0%, 100%);
  --color-secondary-38: hsl(0, 0%, 100%);
  --color-secondary-37: hsl(0, 0%, 100%);
  --color-secondary-36: hsl(0, 0%, 100%);
  --color-secondary-35: hsl(0, 0%, 100%);
  --color-secondary-34: hsl(0, 0%, 100%);
  --color-secondary-33: hsl(0, 0%, 100%);
  --color-secondary-32: hsl(0, 0%, 100%);
  --color-secondary-31: hsl(0, 0%, 100%);
  --color-secondary-30: hsl(0, 0%, 100%);
  --color-secondary-29: hsl(0, 0%, 100%);
  --color-secondary-28: hsl(0, 0%, 100%);
  --color-secondary-27: hsl(0, 0%, 100%);
  --color-secondary-26: hsl(0, 0%, 100%);
  --color-secondary-25: hsl(0, 0%, 100%);
  --color-secondary-24: hsl(0, 0%, 100%);
  --color-secondary-23: hsl(0, 0%, 100%);
  --color-secondary-22: hsl(0, 0%, 100%);
  --color-secondary-21: hsl(0, 0%, 100%);
  --color-secondary-20: hsl(0, 0%, 100%);
  --color-secondary-19: hsl(0, 0%, 100%);
  --color-secondary-18: hsl(0, 0%, 100%);
  --color-secondary-17: hsl(0, 0%, 100%);
  --color-secondary-16: hsl(0, 0%, 100%);
  --color-secondary-15: hsl(0, 0%, 100%);
  --color-secondary-14: hsl(0, 0%, 100%);
  --color-secondary-13: hsl(0, 0%, 100%);
  --color-secondary-12: hsl(0, 0%, 100%);
  --color-secondary-11: hsl(0, 0%, 100%);
  --color-secondary-10: hsl(0, 0%, 100%);
  --color-secondary-9: hsl(0, 0%, 100%);
  --color-secondary-8: hsl(0, 0%, 100%);
  --color-secondary-7: hsl(0, 0%, 100%);
  --color-secondary-6: hsl(0, 0%, 100%);
  --color-secondary-5: hsl(0, 0%, 100%);
  --color-secondary-4: hsl(0, 0%, 100%);
  --color-secondary-3: hsl(0, 0%, 100%);
  --color-secondary-2: hsl(0, 0%, 100%);
  --color-secondary-1: hsl(0, 0%, 100%);

  --color-greyish-hsl: 226, 27%, 33%;
  --color-greyish-100: hsl(226, 27%, 33%);
  --color-greyish-99: hsl(226, 27%, 34%);
  --color-greyish-98: hsl(226, 26%, 34%);
  --color-greyish-97: hsl(226, 25%, 35%);
  --color-greyish-96: hsl(226, 24%, 35%);
  --color-greyish-95: hsl(226, 24%, 36%);
  --color-greyish-94: hsl(226, 23%, 37%);
  --color-greyish-93: hsl(227, 23%, 37%);
  --color-greyish-92: hsl(227, 22%, 38%);
  --color-greyish-91: hsl(227, 21%, 39%);
  --color-greyish-90: hsl(227, 21%, 39%);
  --color-greyish-89: hsl(227, 20%, 40%);
  --color-greyish-88: hsl(227, 20%, 40%);
  --color-greyish-87: hsl(227, 19%, 41%);
  --color-greyish-86: hsl(227, 19%, 42%);
  --color-greyish-85: hsl(227, 18%, 42%);
  --color-greyish-84: hsl(227, 18%, 43%);
  --color-greyish-83: hsl(228, 17%, 44%);
  --color-greyish-82: hsl(228, 17%, 44%);
  --color-greyish-81: hsl(228, 17%, 45%);
  --color-greyish-80: hsl(228, 16%, 46%);
  --color-greyish-79: hsl(228, 16%, 46%);
  --color-greyish-78: hsl(228, 15%, 47%);
  --color-greyish-77: hsl(228, 15%, 47%);
  --color-greyish-76: hsl(228, 15%, 48%);
  --color-greyish-75: hsl(228, 14%, 49%);
  --color-greyish-74: hsl(228, 14%, 49%);
  --color-greyish-73: hsl(228, 14%, 50%);
  --color-greyish-72: hsl(228, 14%, 51%);
  --color-greyish-71: hsl(228, 14%, 51%);
  --color-greyish-70: hsl(229, 14%, 52%);
  --color-greyish-69: hsl(229, 14%, 53%);
  --color-greyish-68: hsl(229, 14%, 53%);
  --color-greyish-67: hsl(229, 14%, 54%);
  --color-greyish-66: hsl(229, 14%, 55%);
  --color-greyish-65: hsl(229, 14%, 55%);
  --color-greyish-64: hsl(229, 14%, 56%);
  --color-greyish-63: hsl(229, 14%, 57%);
  --color-greyish-62: hsl(229, 14%, 57%);
  --color-greyish-61: hsl(229, 14%, 58%);
  --color-greyish-60: hsl(229, 14%, 59%);
  --color-greyish-59: hsl(229, 14%, 59%);
  --color-greyish-58: hsl(229, 14%, 60%);
  --color-greyish-57: hsl(229, 14%, 61%);
  --color-greyish-56: hsl(229, 14%, 61%);
  --color-greyish-55: hsl(229, 14%, 62%);
  --color-greyish-54: hsl(229, 14%, 63%);
  --color-greyish-53: hsl(229, 14%, 63%);
  --color-greyish-52: hsl(229, 14%, 64%);
  --color-greyish-51: hsl(230, 14%, 65%);
  --color-greyish-50: hsl(230, 14%, 65%);
  --color-greyish-49: hsl(230, 14%, 66%);
  --color-greyish-48: hsl(230, 14%, 67%);
  --color-greyish-47: hsl(230, 14%, 67%);
  --color-greyish-46: hsl(230, 14%, 68%);
  --color-greyish-45: hsl(230, 14%, 69%);
  --color-greyish-44: hsl(230, 14%, 69%);
  --color-greyish-43: hsl(230, 14%, 70%);
  --color-greyish-42: hsl(230, 14%, 71%);
  --color-greyish-41: hsl(230, 14%, 71%);
  --color-greyish-40: hsl(230, 14%, 72%);
  --color-greyish-39: hsl(230, 14%, 73%);
  --color-greyish-38: hsl(230, 14%, 73%);
  --color-greyish-37: hsl(230, 14%, 74%);
  --color-greyish-36: hsl(230, 14%, 75%);
  --color-greyish-35: hsl(230, 14%, 75%);
  --color-greyish-34: hsl(230, 14%, 76%);
  --color-greyish-33: hsl(230, 14%, 77%);
  --color-greyish-32: hsl(230, 14%, 77%);
  --color-greyish-31: hsl(230, 14%, 78%);
  --color-greyish-30: hsl(230, 14%, 79%);
  --color-greyish-29: hsl(230, 14%, 80%);
  --color-greyish-28: hsl(230, 14%, 80%);
  --color-greyish-27: hsl(230, 14%, 81%);
  --color-greyish-26: hsl(230, 14%, 82%);
  --color-greyish-25: hsl(230, 14%, 82%);
  --color-greyish-24: hsl(230, 14%, 83%);
  --color-greyish-23: hsl(230, 14%, 84%);
  --color-greyish-22: hsl(230, 14%, 84%);
  --color-greyish-21: hsl(230, 14%, 85%);
  --color-greyish-20: hsl(231, 14%, 86%);
  --color-greyish-19: hsl(231, 14%, 87%);
  --color-greyish-18: hsl(231, 14%, 87%);
  --color-greyish-17: hsl(231, 14%, 88%);
  --color-greyish-16: hsl(231, 14%, 89%);
  --color-greyish-15: hsl(231, 14%, 89%);
  --color-greyish-14: hsl(231, 14%, 90%);
  --color-greyish-13: hsl(231, 14%, 91%);
  --color-greyish-12: hsl(231, 14%, 91%);
  --color-greyish-11: hsl(231, 14%, 92%);
  --color-greyish-10: hsl(231, 14%, 93%);
  --color-greyish-9: hsl(231, 14%, 94%);
  --color-greyish-8: hsl(231, 14%, 94%);
  --color-greyish-7: hsl(231, 14%, 95%);
  --color-greyish-6: hsl(231, 14%, 96%);
  --color-greyish-5: hsl(231, 14%, 96%);
  --color-greyish-4: hsl(231, 14%, 97%);
  --color-greyish-3: hsl(231, 14%, 98%);
  --color-greyish-2: hsl(231, 14%, 99%);
  --color-greyish-1: hsl(231, 14%, 99%);
  --color-greyish-0: hsl(161, 100%, 100%);

  --color-white: hsl(0, 0%, 100%);
  --color-warn: hsl(356, 81%, 48%);
  --color-info: hsl(30, 93%, 50%);

  --icon-color-orange: hsl(35, 88%, 65%);
  --icon-color-blue: hsl(195, 69%, 48%);
  --icon-color-green: hsl(155, 91%, 37%);
  --icon-color-greyish: --color-greyish-90;

  --shadow-grey: 0 2px 4px hsla(var(--color-greyish-hsl), 0.1),
    0px 12px 16px hsla(var(--color-greyish-hsl), 0.05);
  --shadow-primary: 0px 5px 15px hsla(var(--color-primary-hsl), 0.2),
    0px 15px 40px hsla(var(--color-primary-hsl), 0.15);
  --shadow-primary-small: 0px 12.5px 10px -10px hsla(var(--color-primary-hsl), 0.3),
    0px 7.5px 10px -5px hsla(var(--color-primary-hsl), 0.1);

  // Radius
  --radius-small: 5px;
  --radius-medium: 10px;
  --radius-button: 5px;
  --radius-card: 10px;

  // Transitions & Animations

  --transition-fade: 0.2s cubic-bezier(0.4, 1, 0.4, 1);

  // Info Card
  --info-card-header-background: #f6800b;
  --info-card-content-background: #fde6ce;
  --info-card-content-color: #934c05;
  --success-card-header-background: #00a651;
}
