.handle-ion-layout {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.fit-to-ion-layout {
  position: relative;
  height: 100%;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.safe-area {
  padding: var(--ion-safe-area-top) var(--ion-safe-area-right) var(--ion-safe-area-bottom)
    var(--ion-safe-area-left);
}

.overflow {
  &,
  &-x,
  &-y {
    overflow: visible;
  }
}

.no-overflow {
  overflow: hidden;

  &-x {
    overflow-x: hidden;
  }
  &-y {
    overflow-y: hidden;
  }
}

.scroll {
  overflow: scroll;

  &-x {
    overflow-x: scroll;
  }
  &-y {
    overflow-y: scroll;
  }
}

.pointer-event-none {
  pointer-events: none;
}

.rounded {
  border-radius: var(--radius-small);

  &-small {
    border-radius: var(--radius-small);
  }

  &-medium {
    border-radius: var(--radius-medium);
  }

  &-button {
    border-radius: var(--radius-button);
  }

  &-card {
    border-radius: var(--radius-card);
  }
}

.invisible {
  visibility: hidden;
}

.fade {
  opacity: 0.3;
}

.hide-down-1400 {
  display: block;
}

.hide-up-1400 {
  display: none;
}

.mobile-width {
  max-width: 430px;
}

.align- {
  &center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.stretch- {
  &container {
    display: flex;
    flex-direction: column;
  }
  &frame {
    contain: size;
    overflow-y: scroll;
    height: 100%;
  }
  &flex {
    flex-grow: 1;
  }
  &flex-force {
    flex-grow: 1 !important;
  }
  &flex-bounds {
    overflow-y: scroll;
    flex-grow: 1;
    flex-basis: 0;
    padding: 1px;
  }
  &self {
    width: 100%;
    height: 100%;
  }
}

.flex- {
  &fill {
    flex: 1 1 0;
  }
}

.fill-height {
  height: 100%;
}

.fullscreen-modal {
  --min-height: 90vh;
  --min-width: 70vw;
}

.no- {
  &margin {
    margin: 0;
  }
  &padding {
    padding: 0;
  }
}

.gap- {
  &header {
    width: 100%;
    height: 20px;
  }
  &50 {
    width: 100%;
    height: 50px;
  }
  &20 {
    width: 100%;
    height: 20px;
  }
  &15 {
    width: 100%;
    height: 15px;
  }
}

@media only screen and (min-width: 768px) and (min-height: 600px) {
  ion-modal::part(content) {
    --border-radius: var(--radius-card);
  }
  ion-modal.dynamic-modal {
    --border-radius: var(--radius-card);
    &::part(content) {
      width: fit-content;
      height: fit-content;
    }

    & [class*='-modal'] {
      transition: all cubic-bezier(0.2, 1, 0, 1) 0.2s;
      min-height: 50vh !important;
    }
    .small-modal {
      width: 480px;
      height: 70vh !important;
    }
    .medium-modal {
      width: 750px;
      height: 70vh !important;
    }
    .large-modal {
      width: 90vw;
      height: 80vh !important;
    }
    .form-modal {
      width: 750px;
      height: 80vh !important;
    }
    .loading-modal {
      min-width: 480px;
      min-height: 480px;
    }
  }
}

.content {
  --ion-grid-width-xl: 1200px;

  --ion-grid-width-lg: 800px;
  --ion-grid-width-md: 800px;

  --ion-grid-width-sm: 400px;
  --ion-grid-width-xs: 400px;

  padding: 20px;
  box-sizing: border-box;

  &.full-width {
    --ion-grid-width-xl: 100%;
    --ion-grid-width-lg: 100%;
    --ion-grid-width-lg: 100%;
    --ion-grid-width-md: 100%;
  }

  & > ion-row > ion-col {
    padding-left: 0;
    padding-right: 0;
  }

  ion-split-pane & {
    margin: 0;
    padding: 5px 5px 10px 5px;
  }
}

@media only screen and (max-width: 1399px) {
  .hide-down-1400 {
    display: none !important;
  }
  .hide-up-1400 {
    display: block !important;
  }
}

@media only screen and (max-width: 767px) {
  ion-tabs .content {
    padding: 70px 0 10px 0;
  }

  .block-button-mobile {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}

@media only screen and (orientation: landscape) {
  .landscape-layout {
    --ion-grid-width-sm: 600px;
    --ion-grid-width-xs: 600px;
  }
}

@media only screen and (min-width: 1200px) and (min-height: 600px) {
  ion-modal.dynamic-modal {
    .form-modal {
      width: 1170px;
    }
  }
}

@media (orientation: landscape) {
  @supports (padding: var(--ion-safe-area-left)) {
    ion-toolbar {
      padding-left: calc(-1 * var(--ion-safe-area-left));
      padding-right: calc(-1 * var(--ion-safe-area-right));

      fa-icon[slot='start'] {
        margin-left: calc(-1 * var(--ion-safe-area-left));
      }
    }
  }
}

ion-tabs {
  margin-top: var(--ion-safe-area-top);
  margin-bottom: var(--ion-safe-area-bottom);
  margin-left: var(--ion-safe-area-left);
  margin-right: var(--ion-safe-area-right);
}

ion-refresher {
  z-index: 1;
  margin-top: -12px;
  @media (max-width: 768px) {
    margin-top: 54px;
  }
  ion-refresher-content {
    container: refresher-content-container / inline-size;
    .custom-refresher-content {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 50px;
      .tie-loader {
        height: 50px;
        width: auto;
      }
    }
  }

  @container refresher-content-container (width > 800px) {
    .custom-refresher-content {
      max-width: 800px;
    }
  }
  @container refresher-content-container (width > 1200px) {
    .custom-refresher-content {
      max-width: 1200px;
    }
  }

  &.refresher-completing {
    opacity: 0;
    transition: all 0.3s ease-out;
  }
}

.menu-item {
  &.menu-item-warning {
    --background: var(--info-card-content-background) !important;
    --background-hover: var(--info-card-content-background) !important;
    --color: var(--info-card-content-color) !important;
    color: var(--info-card-content-color) !important;
    --color-hover: var(--info-card-content-color) !important;
    ion-label {
      --color: var(--info-card-content-color) !important;
      color: var(--info-card-content-color) !important;
      --color-hover: var(--info-card-content-color) !important;
    }
    fa-icon {
      color: var(--info-card-content-color) !important;
    }
  }
}
