/* Ionic Resets */

.ion-valid {
  --highlight-color: var(--color-greyish-100) !important;
}

.legacy-input.sc-ion-input-ios-h .native-input[disabled].sc-ion-input-ios,
.input-disabled.sc-ion-input-ios-h {
  opacity: unset;
}

/* Custom CSS */
.hidden-label label {
  min-height: 0 !important;

  .label-text-wrapper {
    display: none !important;
  }
}

ion-item.custom-label-input-item {
  .label-input {
    padding-bottom: 0.3em;
  }
}

.outer-label-input {
  padding-bottom: 0.3em;
}

ion-popover {
  &.date-picker,
  &.time-picker {
    @media ((max-width: 480px) or (max-height: 780px)) {
      .popover-viewport {
        overflow: auto;
      }
      &::part(content) {
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
      }
    }
  }
  &.date-picker {
    --min-width: 350px;
    --min-heigth: 450px;
    .popover-viewport {
      min-height: 405px;
    }
    &::part(content) {
      min-height: min(405px, calc(100dvh - 30px));
    }
  }
  &.time-picker {
    --min-width: 240px;
    --min-height: 250px;
  }
}

ion-select-popover {
  * {
    font-size: var(--font-p1-font-size) !important;
    --inner-border-width: 0px;
    --inner-padding-end: 10px !important;
    --color-checked: var(--color-primary-100);
  }

  .select-interface-option,
  .ion-focused {
    --background: var(--color-white) !important;
    --background-focused: transparent !important;
    --background-focused-opacity: 1;
    --background-hover: var(--color-greyish-5) !important;
    --background-hover-opacity: 1;
  }

  .item-radio-checked {
    --background: var(--color-greyish-10) !important;
  }

  .list-md {
    padding: 0 !important;
    margin: var(--ion-safe-area-top) 0 var(--ion-safe-area-bottom) !important;
  }
}

.progress-bar {
  height: 9px;
  border-radius: var(--radius-small);
  &::part(track) {
    background: var(--color-greyish-15);
  }
  &::part(progress) {
    background: var(--color-greyish-70);
  }

  &-colorful {
    &::part(track) {
      background: var(--color-info);
      filter: opacity(20%);
    }
    &::part(progress) {
      background: var(--color-info);
      filter: opacity(50%);
    }
  }
}

ion-input .required {
  color: var(--color-info);
}

ion-datetime::part(month-year-button) {
  background: var(--color-greyish-20);
  color: var(--color-greyish-95);
  border-radius: var(--radius-button);
  box-shadow:
    inset -3px 0px 0px 7px white,
    inset -10px 0px 0px 7px white,
    inset 0 0 0 500px var(--color-greyish-10);
  margin: 3px 5px 5px;

  &:hover {
    background: var(--color-greyish-40);
    color: var(--color-greyish-95);
    --color: var(--color-greyish-95);
  }
}

form,
ng-form,
.form {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .top {
    flex-grow: 1;
  }

  .button-section {
    ion-button {
      margin-bottom: 10px;
    }
  }

  .field-section {
    padding: 0;
    --padding-inline-start: 0;
    --padding-start: 0;
    --min-height: 0;
    &::part(native) {
      padding: 0;
    }
  }

  ion-item {
    --inner-padding-end: 0;
    --padding-start: 0;
    font-size: var(--font-p2-font-size, 14px) !important;
    &,
    & * {
      --background-hover: var(--color-white) !important;
      --background-active: var(--color-white) !important;
      --background-focus: var(--color-white) !important;
    }
    &.select-expanded {
      background-color: var(--color-white) !important;
    }
    &.gap {
      height: 20px;
    }
  }

  .ion-item {
    p {
      margin: 0 0 5px;
    }
  }

  .item-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;

    button.edited {
      height: 36px;
      width: 36px;
      align-self: center;
    }
  }

  ion-input {
    --background: transparent !important;
    label {
      padding-left: 0px;
      .label-text-wrapper {
        transform: none !important;
        padding: 5px 0;
      }
      .native-wrapper {
        .native-input {
          background: var(--color-greyish-5);
          padding: 8px 15px;
          margin: 0 !important;
        }
      }
    }

    &.edited .native-input {
      background: var(--color-greyish-15) !important;
    }

    &.input-disabled label .native-wrapper .native-input {
      color: var(--color-greyish-70);
    }

    .input-bottom {
      border-top: none !important;
      .counter {
        color: var(--color-info) !important;
      }
      .error-text {
        color: var(--color-warn) !important;
      }
    }
  }

  ion-textarea {
    color: var(--color-greyish-100);
    background: var(--color-greyish-5) !important;
    border-radius: var(--radius-small) !important;
    --padding-start: 15px !important;
    --padding-top: 10px !important;
    --padding-bottom: 10px !important;
    &,
    * {
      color: var(--color-greyish-100);
    }
  }

  .invalid {
    color: var(--color-warn);
  }

  .warn {
    color: var(--color-info);
  }

  .helper {
    margin-top: 5px;
  }

  ion-item-divider {
    z-index: 1000;
  }

  .placeholder {
    opacity: 0.5;
  }

  .preview {
    #form-content {
      opacity: 0.7;
      cursor: no-drop;
      *:not(input[type='radio']) {
        pointer-events: none;
        opacity: 1 !important;
      }
    }
  }

  .native-textarea[disabled],
  .native-input[disabled] {
    cursor: not-allowed;
    pointer-events: auto;
  }

  .input {
    padding: 8px 15px;
    background: var(--color-greyish-5);
    width: 100%;
    margin: 0;
  }

  .checkbox-field {
    ion-checkbox[color='primary'] {
      margin-right: 1em;
    }
    &.item-disabled {
      opacity: var(--opacity, 0.7);
    }
  }

  .date-field {
    .date-time-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 10px;
      flex-wrap: wrap;
      .date-input {
        flex-basis: 130px;
        min-width: 130px;
        flex-grow: 1;
      }
      .time-input {
        flex-basis: 0px;
        flex-grow: 1;
        min-width: 77px;
      }
    }

    .invisible-input {
      visibility: hidden;
      width: 0;
      height: 0;
      position: absolute;
      overflow: none;
      opacity: 0;
    }
    &.item-disabled {
      opacity: 1;
      cursor: not-allowed;
      pointer-events: auto;
      .ion-input {
        opacity: var(--opacity, 0.7);
        pointer-events: none;
      }
    }
    ion-datetime {
      margin: auto;
      padding-right: 20px;
    }
  }

  .reset-button {
    align-self: flex-start;
    margin: 0px 0px 8px 10px;

    &-selection {
      padding: 8px;
      .icon-container {
        width: var(--line-height);
        height: var(--line-height);
      }
    }

    &-radio {
      margin: 0px 0px 0px 10px;
    }
  }

  .text-area-field {
    .reset-button {
      align-self: center;
    }
  }
}
