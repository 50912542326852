full-calendar {
  height: 100%;
  max-height: 100%;
  * {
    border: none !important;
  }
  .fc-event-title {
    display: none;
  }
  .fc-daygrid-body table {
    display: flex;

    colgroup {
      display: none;
    }
    tbody {
      display: flex;
      flex-direction: column;
      gap: 3px;
      padding: 3px;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      tr {
        display: grid;
        grid-template-columns: repeat(7, minmax(0, 1fr));
        gap: 3px;
        flex-grow: 1;
        td {
          border-radius: calc(var(--radius-card) / 2);
          background: var(--color-greyish-3);
          --fc-today-bg-color: var(--color-greyish-10);
          contain: strict;

          &:nth-child(n + 6) {
            background-color: var(--color-greyish-1);
          }
          .fc-daygrid-day-frame {
            height: 100%;
            display: flex;
            flex-direction: column;

            .fc-daygrid-day-events {
              flex-grow: 1;
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              gap: 0 5px;
              padding: 0 5px 5px;
              justify-content: flex-end;

              // item-container
              .fc-daygrid-event-harness {
                width: 100%;
                border-radius: calc(var(--radius-card) / 3);
                background-color: var(--color-greyish-20);
                overflow: hidden;
                margin-top: 4px !important;

                // item-link-wrapper
                .fc-event {
                  padding: unset;
                  margin: unset;
                  height: 100%;

                  &.fc-event-past {
                    opacity: 0.7;
                    .marker {
                      opacity: 0.3;
                    }
                  }

                  // custom-item
                  .event-item {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 5px;
                    padding-right: 5px;
                    container-type: inline-size;

                    .marker {
                      width: 3px;
                      margin: 4px;
                      margin-right: 0;
                      border-radius: var(--radius-small);
                      height: calc(100% - 8px);
                      display: block;
                      background: var(--color-greyish-100);
                    }
                    .icon {
                      color: var(--color-greyish-100);
                    }
                    .time {
                      margin-left: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@container (width < 50px) {
  .time {
    display: none;
  }
  .icon {
    flex-grow: 1;
    text-align: center;
    padding: 3px 0;
  }
}

@media screen and (min-width: 768px) {
  full-calendar {
    .fc-daygrid-body table {
      tbody {
        gap: 10px;
        padding: 10px;
        tr {
          gap: 10px;
        }
      }
    }
  }
}
