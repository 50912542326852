@font-face {
  font-family: 'SF REGULAR';
  src: url('../../../../shared/src/lib/assets/font/NotoSans-Regular.ttf');
}

@font-face {
  font-family: 'SF MEDIUM';
  src: url('../../../../shared/src/lib/assets/font/NotoSans-Medium.ttf');
}

@font-face {
  font-family: 'SF SEMIBOLD';
  src: url('../../../../shared/src/lib/assets/font/NotoSans-SemiBold.ttf');
}

@font-face {
  font-family: 'SF BOLD';
  src: url('../../../../shared/src/lib/assets/font/NotoSans-Bold.ttf');
}

body {
  --ion-font-family: var(--font-p);
  font-family: var(--font-p);
  font-size: var(--font-p-font-size);
  line-height: var(--font-p-line-height);
  color: var(--color-greyish-100);
}

body * {
  hyphens: auto;
}

h1,
.h1 {
  --line-height: calc(var(--font-h1-font-size) * 1.56);
  --ion-font-family: var(--font-h1);
  font-family: var(--font-h1);
  color: var(--color-greyish-100);
  font-size: var(--font-h1-font-size, 32px);
  line-height: var(--font-h1-line-height, var(--line-height));
  font-weight: var(--font-h1-weight);
  &.align {
    text-align: var(--font-h1-text-align, left);
  }
  ion-card-content & {
    font-size: var(--font-h1-font-size) !important;
  }
}

h2,
.h2 {
  --line-height: calc(var(--font-h2-font-size) * 1.318);
  --ion-font-family: var(--font-h2);
  font-family: var(--font-h2);
  color: var(--color-greyish-100);
  font-size: var(--font-h2-font-size);
  line-height: var(--font-h2-line-height, var(--line-height));
  font-weight: var(--font-h2-weight);
  &.align {
    text-align: var(--font-h2-text-align, left);
  }
  ion-card-content & {
    font-size: var(--font-h2-font-size) !important;
  }
}

h3,
.h3 {
  --line-height: calc(var(--font-h3-font-size) * 1.375);
  --ion-font-family: var(--font-h3);
  font-family: var(--font-h3);
  color: var(--color-greyish-100);
  font-size: var(--font-h3-font-size);
  line-height: var(--font-h3-line-height, var(--line-height));
  font-weight: var(--font-h3-weight);
  &.align {
    text-align: var(--font-h3-text-align, left);
  }
  ion-card-content & {
    font-size: var(--font-h3-font-size) !important;
  }
}

h4,
.h4 {
  --line-height: calc(var(--font-h4-font-size));
  --ion-font-family: var(--font-h4);
  font-family: var(--font-h4);
  color: var(--color-greyish-100);
  font-size: var(--font-h4-font-size);
  line-height: var(--font-h4-line-height, var(--line-height));
  font-weight: var(--font-h4-weight);
  &.align {
    text-align: var(--font-h4-text-align, left);
  }
  ion-card-content & {
    font-size: var(--font-h4-font-size) !important;
  }
}

h5,
.h5 {
  --line-height: calc(var(--font-h5-font-size));
  --ion-font-family: var(--font-h5);
  font-family: var(--font-h5);
  color: var(--color-greyish-100);
  font-size: var(--font-h5-font-size);
  line-height: var(--font-h5-line-height, var(--line-height));
  font-weight: var(--font-h5-weight);
  &.align {
    text-align: var(--font-h5-text-align, left);
  }
  ion-card-content & {
    font-size: var(--font-h5-font-size) !important;
  }
}

p,
.p,
ion-text,
li {
  --line-height: calc(var(--font-p-font-size) * 1.25);
  --ion-font-family: var(--font-p);
  font-family: var(--font-p);
  color: var(--color-greyish-100);
  font-size: var(--font-p-font-size);
  line-height: var(--font-p-line-height, var(--line-height));
  font-weight: var(--font-p-weight);
  &.align {
    text-align: var(--font-p-text-align, left);
  }
  ion-label & {
    --line-height: calc(var(--font-p-font-size) * 1.25);
    color: var(--color-greyish-100) !important;
    font-size: var(--font-p-font-size) !important;
    line-height: var(--font-p-line-height, var(--line-height)) !important;
  }

  ion-card-content & {
    font-size: var(--font-p-font-size) !important;
  }
}

ion-input,
.input,
ion-select,
ion-textarea,
.p1 {
  --line-height: calc(var(--font-p1-font-size) * 1.25) !important;
  --ion-font-family: var(--font-p1);
  font-family: var(--font-p1);
  color: var(--color-greyish-100);
  font-size: var(--font-p1-font-size) !important;
  line-height: var(--font-p-line-height, var(--line-height)) !important;
  font-weight: var(--font-p1-weight);
  &.align {
    text-align: var(--font-p1-text-align, left);
  }
  ion-menu-toggle & {
    --line-height: calc(var(--font-p1-font-size) * 1.25);
    font-size: var(--font-p1-font-size) !important;
    line-height: var(--font-p1-line-height, var(--line-height)) !important;
  }

  ion-card-content & {
    font-size: var(--font-p1-font-size) !important;
  }
}

.p2 {
  --line-height: calc(var(--font-p2-font-size) * 1.25);
  --ion-font-family: var(--font-p2);
  font-family: var(--font-p2);
  color: var(--color-greyish-100);
  font-size: var(--font-p2-font-size);
  line-height: var(--font-p2-line-height, var(--line-height));
  font-weight: var(--font-p2-weight);
  &.align {
    text-align: var(--font-p2-text-align, left);
  }
  ion-card-content & {
    font-size: var(--font-p2-font-size) !important;
  }
}

ion-button,
.p3 {
  --line-height: calc(var(--font-p3-font-size) * 1.25);
  --ion-font-family: var(--font-p3);
  font-family: var(--font-p3);
  color: var(--color-greyish-100);
  font-size: var(--font-p3-font-size);
  line-height: var(--font-p3-line-height, var(--line-height));
  font-weight: var(--font-p3-weight);
  &.align {
    text-align: var(--font-p3-text-align, left);
  }
  ion-card-content & {
    font-size: var(--font-p3-font-size) !important;
  }
}

strong,
.strong {
  font-family: var(--font-p3);
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: var(--font-p3-weight);
}

.p4 {
  --line-height: calc(var(--font-p4-font-size) * 1.25);
  --ion-font-family: var(--font-p4);
  font-family: var(--font-p4);
  color: var(--color-greyish-100);
  font-size: var(--font-p4-font-size);
  line-height: var(--font-p4-line-height, var(--line-height));
  font-weight: var(--font-p4-weight);
  &.align {
    text-align: var(--font-p4-text-align, left);
  }
  ion-card-content & {
    font-size: var(--font-p4-font-size) !important;
  }
}

a,
.a {
  --ion-font-family: var(--font-a);
  font-family: var(--font-a);
  color: var(--color-primary-100);
  text-decoration: none;
  font-size: var(--font-a-font-size);
  line-height: 16px;
  font-weight: var(--font-a-weight);
  cursor: pointer;
  &.align {
    text-align: var(--font-a-text-align, left);
  }
  ion-card-content & {
    font-size: var(--font-a-font-size) !important;
  }
}

.label-input,
.label-input * {
  --line-height: calc(var(--font-label-input-font-size) * 1.25) !important;
  --ion-font-family: var(--font-label-input);
  font-family: var(--font-label-input);
  color: var(--color-greyish-100) !important;
  font-size: var(--font-label-input-font-size) !important;
  line-height: var(--font-label-input-line-height, var(--line-height)) !important;
  font-weight: var(--font-label-input-weight);
  &.align {
    text-align: var(--font-label-input-text-align, left);
  }
  ion-card-content & {
    font-size: var(--font-label-input-font-size) !important;
  }
}

ion-label,
.label,
ion-back-button {
  --ion-font-family: var(--font-label-input);
  font-family: var(--font-label-input);
  color: var(--color-greyish-100);
  font-size: var(--font-label-input-font-size);
  line-height: var(--font-label-input-line-height);
  text-align: var(--font-label-input-text-align, left);
  font-weight: var(--font-label-input-weight);
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.underline {
  text-decoration: underline;
}

.no-hyphens {
  hyphens: none !important;
}

.like {
  height: var(--font-p-font-size);

  &-p {
    height: var(--font-p-font-size);
  }

  &-h1 {
    height: var(--font-h1-font-size);
  }

  &-h2 {
    height: var(--font-h2-font-size);
  }

  &-h3 {
    height: var(--font-h3-font-size);
  }
}

ion-tab-bar p {
  text-align: center;
  white-space: nowrap;
}

@media only screen and (max-width: 1199px) {
  h4,
  .h4 {
    --line-height: calc(var(--font-h4-mobile-font-size) * 1.05);
    --ion-font-family: var(--font-h4-mobile);
    font-family: var(--font-h4-mobile);
    color: var(--color-greyish-100);
    font-size: var(--font-h4-mobile-font-size);
    line-height: var(--font-h4-mobile-line-height, var(--line-height));
    font-weight: var(--font-h4-mobile-weight);

    ion-card-content & {
      font-size: var(--font-h4-mobile-font-size) !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  h1 {
    --line-height: calc(var(--font-h1-mobile-font-size) * 1.346);
    --ion-font-family: var(--font-h1-mobile);
    font-family: var(--font-h1-mobile);
    font-size: var(--font-h1-mobile-font-size);
    line-height: var(--font-h1-mobile-line-height, var(--line-height));
    text-align: var(--font-h1-mobile-text-align, left);
    font-weight: var(--font-h1-mobile-weight);

    ion-card-content & {
      font-size: var(--font-h1-mobile-font-size) !important;
    }
  }

  h2 {
    --line-height: calc(var(--font-h2-mobile-font-size) * 1.318);
    --ion-font-family: var(--font-h2-mobile);
    font-family: var(--font-h2-mobile);
    font-size: var(--font-h2-mobile-font-size);
    line-height: var(--font-h2-mobile-line-height, var(--line-height));
    text-align: var(--font-h2-mobile-text-align, left);
    font-weight: var(--font-h2-mobile-weight);

    ion-card-content & {
      font-size: var(--font-h2-mobile-font-size) !important;
    }
  }

  h3 {
    --line-height: calc(var(--font-h3-mobile-font-size) * 1.25);
    --ion-font-family: var(--font-h3-mobile);
    font-family: var(--font-h3-mobile);
    font-size: var(--font-h3-mobile-font-size);
    line-height: var(--font-h3-mobile-line-height, var(--line-height));
    text-align: var(--font-h3-mobile-text-align, left);
    font-weight: var(--font-h3-mobile-weight);

    ion-card-content & {
      font-size: var(--font-h3-mobile-font-size) !important;
    }
  }

  h5 {
    --line-height: calc(var(--font-h5-mobile-font-size) * 1.25);
    --ion-font-family: var(--font-h5-mobile);
    font-family: var(--font-h5-mobile);
    font-size: var(--font-h5-mobile-font-size);
    line-height: var(--font-h5-mobile-line-height, var(--line-height));
    text-align: var(--font-h5-mobile-text-align, left);
    font-weight: var(--font-h5-mobile-weight);

    ion-card-content & {
      font-size: var(--font-h5-mobile-font-size) !important;
    }
  }

  p,
  .p,
  ion-text {
    --line-height: calc(var(--font-p-mobile-font-size) * 1.357);
    --ion-font-family: var(--font-p-mobile);
    font-family: var(--font-p-mobile);
    font-size: var(--font-p-mobile-font-size);
    line-height: var(--font-p-mobile-line-height, var(--line-height));
    text-align: var(--font-p-mobile-text-align, left);
    font-weight: var(--font-p-mobile-weight);

    ion-card-content & {
      font-size: var(--font-p-mobile-font-size) !important;
    }
  }

  .p2 {
    --line-height: calc(var(--font-p2-mobile-font-size) * 1.25);
    --ion-font-family: var(--font-p2-mobile);
    font-family: var(--font-p2-mobile);
    color: var(--color-greyish-100);
    font-size: var(--font-p2-mobile-font-size);
    line-height: var(--font-p-line-height, var(--line-height));
    text-align: var(--font-p2-mobile-text-align, left);
    font-weight: var(--font-p2-mobile-weight);

    ion-card-content & {
      font-size: var(--font-p2-mobile-font-size) !important;
    }
  }

  .p3 {
    --line-height: calc(var(--font-p3-mobile-font-size) * 1.25);
    --ion-font-family: var(--font-p3-mobile);
    font-family: var(--font-p3-mobile);
    color: var(--color-greyish-100);
    font-size: var(--font-p3-mobile-font-size);
    line-height: var(--font-p-line-height, var(--line-height));
    text-align: var(--font-p3-mobile-text-align, left);
    font-weight: var(--font-p3-mobile-weight);

    ion-card-content & {
      font-size: var(--font-p3-mobile-font-size) !important;
    }
  }

  .p4 {
    --line-height: calc(var(--font-p4-mobile-font-size) * 1.25);
    --ion-font-family: var(--font-p4-mobile);
    font-family: var(--font-p4-mobile);
    color: var(--color-greyish-100);
    font-size: var(--font-p4-mobile-font-size);
    line-height: var(--font-p-line-height, var(--line-height));
    text-align: var(--font-p4-mobile-text-align, left);
    font-weight: var(--font-p4-mobile-weight);

    ion-card-content & {
      font-size: var(--font-p4-mobile-font-size) !important;
    }
  }
}

// new css concept

.text- {
  &transform- {
    &uppercase {
      text-transform: uppercase;
    }
  }
}
