.shape- {
  &card {
    border-radius: var(--radius-card);
    box-shadow: var(--shadow-grey);
    contain: paint;
  }

  &list {
    border-radius: var(--radius-card);
    border: 1px solid var(--color-greyish-5);
    contain: paint;
  }

  &skeleton-text {
    border-radius: var(--radius-small);
  }

  &chip {
    border-radius: 12px;
    padding: 2px 10px;

    display: flex;
    align-content: center;
    justify-content: center;
  }

  &field {
    border-radius: var(--radius-small);
    contain: paint;
  }

  &button {
    border-radius: var(--radius-button);
    padding: 8px 14px;
  }

  &square {
    aspect-ratio: 1 / 1;
  }
}

@mixin icon-only {
  flex: none;
  &::part(native) {
    padding: 10px;
    width: 35px;
    height: 35px;
  }
}
