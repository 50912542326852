@import 'icons';
@import 'mixins';
.form-input {
  color: var(--color-greyish-100);
  display: flex;
  gap: 2px;
  position: relative;
  container-name: form-input;
  container-type: inline-size;

  @mixin placeholder-style {
    color: var(--color-greyish-70) !important;
    opacity: 0.7;
  }

  @mixin after {
    &::after {
      position: absolute;
      top: calc(50% - 8px);
      right: 10px;
      height: 16px;
      width: 16px;
      content: '';
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      cursor: pointer;
      z-index: 1;
      @content;
    }
  }

  @mixin after-lock {
    @include after {
      @include svg-lock;
      cursor: not-allowed;
      opacity: 1;
      background-size: 14px 14px;
    }
  }

  @mixin after-file-arrow-up {
    @include after {
      @include file-arrow-up-solid;
      background-size: 12px auto;
      opacity: 0.3;
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 1;
    @include after-lock;
    input,
    textarea {
      &:not(.time):not(.date) {
        padding-right: 29px;
      }
    }
    .checkbox {
      fa-icon {
        color: var(--color-greyish-100);
      }
    }
  }

  &.edited,
  & .edited {
    & input,
    & textarea,
    & .select {
      @include bold;
      &::after,
      &::before {
        @include bold;
      }
    }
    input,
    textarea {
      background-color: var(--color-greyish-15);
      @include bold;
    }
    .checkbox,
    label,
    .select {
      background-color: var(--color-greyish-15) !important;
    }
    button {
      background-color: var(--color-greyish-15) !important;
    }
    .file-input-container {
      &::before {
        @include bold;
        background-color: var(--color-greyish-15);
      }
    }
  }

  .show-on-condensed {
    display: none;
  }

  &:not(.card-layout) > * {
    border-radius: 0px !important;

    &:first-child {
      border-top-left-radius: var(--radius-small) !important;
      border-bottom-left-radius: var(--radius-small) !important;
    }
    &:last-child {
      border-top-right-radius: var(--radius-small) !important;
      border-bottom-right-radius: var(--radius-small) !important;
    }
  }

  .toggle-option {
    display: flex;
    position: relative;
    overflow: hidden;
    input {
      z-index: 1;
      cursor: pointer;
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
    flex: 1;
    label {
      display: flex;
      align-items: center;
      padding: 8px 12px;
      gap: 10px;
      min-height: 36px;
      flex-grow: 1;
      pointer-events: all;
      cursor: pointer;
      span.toggle-point {
        height: 16px;
        width: 16px;
        border: 2px solid var(--color-greyish-40);
        border-radius: 50%;
      }
      span.toggle-value {
        transform: translateY(0.3px);
      }
    }
    input:checked + label {
      span.toggle-point {
        border-width: 5.5px;
        border-color: var(--color-primary-100);
        &::before {
          content: '';
          display: block;
          border-radius: 50%;
          background-color: white;
          width: 100%;
          height: 100%;
        }
      }
    }
    input:focus + label {
      outline: 5px auto Highlight;
      outline: 5px auto -webkit-focus-ring-color;
    }
  }

  input,
  textarea {
    flex-grow: 1;
    width: 100%;
    margin: 0;
    padding: 8px 15px 8px 15px;
    height: 36px;
    border: none;
    background-color: var(--color-greyish-5);
    &.time {
      flex-basis: 0px;
      min-width: 75px;
    }
    &.date {
      flex-basis: 130px;
      min-width: 130px;
    }
    &::placeholder {
      @include placeholder-style;
    }
  }

  input[type='number'] {
    appearance: none;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type='number'] {
      appearance: textfield;
    }
  }

  textarea {
    height: unset;
    resize: vertical;
    overflow: auto;
  }

  .file-input-container {
    flex-grow: 1;
    position: relative;
    pointer-events: all;
    &::before {
      box-sizing: border-box;
      pointer-events: all;
      padding: 8px 15px;
      content: attr(placeholder);
      position: absolute;
      background-color: var(--color-greyish-5);
      max-height: 36px;
      width: 100%;
      top: 0;
      left: 0;
      border-radius: var(--radius-small) 0px 0px var(--radius-small);
      color: var(--color-greyish-70);
      z-index: 1;
      height: 36px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &.valid {
      &::before,
      &::after {
        color: var(--color-greyish-100);
        opacity: 1;
      }
    }

    @include after-file-arrow-up;
    input[type='file'] {
      z-index: 2;
      height: 36px;
      width: 100%;
      border-radius: 0px;
      appearance: none;
      opacity: 0;
      position: relative;
    }
  }

  div.select {
    width: 100%;
    flex-grow: 1;
    padding: 8px 29px 8px 15px;
    border: none;
    background-color: var(--color-greyish-5);
    text-overflow: ellipsis;
    white-space: break-spaces;
    option {
      white-space: break-spaces;
    }

    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-repeat: no-repeat;

    &:not(.disabled) {
      background-position: calc(100% - 11px) center;
      background-size: 18px;
      @include svg-chevron-expand;
      cursor: pointer;
    }

    &.no-value-selected:not(.disabled) {
      .select-value {
        @include placeholder-style;
      }
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 1;
      @include after-lock;
      color: var(--color-greyish-100) !important;
    }
  }

  button {
    background-color: var(--color-greyish-20);
    width: 36px;
    min-width: 36px;
    flex-basis: 36px;
    &:hover {
      background-color: var(--color-greyish-10);
    }
    &:disabled {
      background-color: var(--color-greyish-5);
      fa-icon {
        opacity: 0.5;
      }
    }
    fa-icon {
      svg {
        height: 13px;
        width: auto;
      }
    }
  }

  .checkbox {
    padding: 8px 15px;
    flex-grow: 1;
    background-color: var(--color-greyish-5);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    font-size: 16px;
    font-weight: 400;
    fa-icon {
      font-size: 18px;
      color: var(--color-primary-100);
    }
    input {
      display: hidden;
      position: absolute;
      height: 0px;
      width: 0px;
      overflow: hidden;
      opacity: 0;
    }
    &.disabled-padding {
      padding-right: 29px;
    }

    fa-icon {
      position: relative;
      z-index: 0;
      &.filled-checkbox::after {
        content: '';
        display: block;
        position: absolute;
        top: 3px;
        left: 0px;
        width: 15px;
        height: 14px;
        border-radius: var(--radius-small);
        background-color: white;
        z-index: -1;
      }
    }
  }

  .no-options-available {
    flex-grow: 1;
    border-radius: var(--radius-small) !important;
    background-color: var(--color-greyish-5);
    p {
      @include placeholder-style;
      border-radius: var(--radius-small) !important;
    }
  }
  .hidden-form-element {
    position: absolute !important;
    width: 0px !important;
    height: 0px !important;
    overflow: hidden !important;
    display: hidden !important;
    opacity: 0 !important;
    padding: 0px !important;
    margin: 0px !important;
  }

  .input-preview {
    min-height: 36px;
    padding: 8px 29px 8px 15px;
    background-color: var(--color-greyish-5);
    margin: 0px;
    flex-grow: 1;
    border-radius: var(--radius-small) !important;
    word-break: break-word;
  }
  p {
    font-size: 16px;
  }
}

.form-input-loader {
  display: flex;
  align-items: center;
  gap: 15px;
  img {
    height: 36px;
    width: auto;
    margin-left: 2px;
  }
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@container form-input (width < 325px) {
  .date-btn.hide-on-condensed {
    display: none;
  }
  .date-btn.show-on-condensed {
    display: block !important;
  }
}

@container form-input (width < 220px) {
  .toggle-option {
    .toggle-point {
      display: none;
    }
    input:checked + label {
      @include bold;
    }
    label {
      padding: 8px 0px !important;
      justify-content: center;
    }
  }
}

@container form-input (width < 210px) {
  .hide-on-condensed:not(.date-btn) {
    display: none;
  }
  .show-on-condensed:not(.date-btn) {
    display: block !important;
  }
}

@container form-input (width < 175px) {
  .date-btn.datepicker-button {
    display: none;
  }
}

@container form-input (width < 150px) {
  .form-input .reset-button-new {
    display: none;
  }
  .form-input > *:not(.toggle-option) {
    border-radius: var(--radius-small) !important;
    &::placeholder {
      color: transparent;
    }
  }
  .form-input > .toggle-option:last-of-type {
    border-radius: 0px var(--radius-small) var(--radius-small) 0px !important;
  }
}
